import React, { useEffect } from 'react';

import { Button } from '@components/Button';
import Loading from '@components/Loading';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import SubscriptionsResult from './SubscriptionsResult';

import { CreditCardCode } from '@domain/my-donations/constants';

import { useGetSubscriptionsForMember } from '@repositories/my-donations';
import { useFetchProfileDetails } from '@repositories/profile/hooks';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const SubscriptionsList: React.FC = () => {
  const { user } = useAppConfig();
  const individualId = user?.individualId || '';
  const { t } = useTranslation();
  const [
    fetchSubscriptions,
    {
      data: subscriptionData,
      loading: subscriptionsLoading,
      error: subscriptionsError,
    },
  ] = useGetSubscriptionsForMember();

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
  } = useFetchProfileDetails(individualId);
  const netForumMemberId =
    profileData?.individual.riIndividualId?.toString() || '';

  useEffect(() => {
    if (netForumMemberId) {
      fetchSubscriptions({
        variables: {
          MemberID: netForumMemberId,
        },
      });
    }
  }, [fetchSubscriptions, netForumMemberId]);

  if (profileError || !individualId) {
    return <p>Error loading profile data.</p>;
  }

  if (profileLoading) {
    return <Loading small />;
  }

  if (!netForumMemberId) {
    return null;
  }

  const subscriptions = subscriptionData?.subscriptions || [];

  return (
    <div>
      {subscriptionsLoading && (
        <div data-testid="subscription-loader">
          <Loading small />
        </div>
      )}
      {!subscriptionsLoading && subscriptions.length === 0 && (
        <div>
          <p>
            {t(
              'subscriptions-list.not-found-msg',
              'You do not have any active recurring donations at this time.'
            )}
          </p>
          <p className="mb-0 font-bold text-black text-sm">
            {t(
              'subscription-list.add-new-label',
              'Add a new recurring donation'
            )}
          </p>
          <Button isMinWidth={false} editButton className="mt-2">
            {t('subscription-list.add-button-label', 'ADD')}
          </Button>
        </div>
      )}
      {subscriptionsError && (
        <div>
          {t(
            'subscriptions-list.error-loading-msg',
            'Error loading subscriptions.'
          )}
        </div>
      )}

      {!subscriptionsLoading && subscriptions.length > 0 && (
        <ResultsList
          darkBackground
          className="-mx-8 tablet:mx-0 border-t-0"
          listId="subscriptions-results-list"
        >
          {subscriptions.map(
            item =>
              item && (
                <ResultsListItem key={item.SubscriptionId}>
                  <SubscriptionsResult
                    SubscriptionId={item.SubscriptionId || ''}
                    FundName={item.FundName}
                    TranslatedFrequency={item.TranslatedFrequency}
                    Amount={item.Amount}
                    Frequency={item.Frequency}
                    NextChargeTimestamp={item.NextChargeTimestamp}
                    Name={
                      item.Individual?.LocalizedName
                        ? item.Individual.LocalizedName
                        : `${item.Individual?.FirstName} ${item.Individual?.LastName}`
                    }
                    PhoneNumber={item.PhoneNumber}
                    EmailAddress={item.EmailAddress}
                    Address={{
                      AddressLine1: item.Address?.AddressLine1 || '',
                      AddressLine2: item.Address?.AddressLine2 || '',
                      AddressLine3: item.Address?.AddressLine3 || '',
                      City: item.Address?.City || '',
                      State: item.Address?.State || '',
                      Country: item.Address?.Country || '',
                      PostalCode: item.Address?.PostalCode || '',
                      InternationalProvince:
                        item.Address?.InternationalProvince || '',
                    }}
                    Payment={{
                      PaymentType: item.Payment?.PaymentType as CreditCardCode,
                      MaskedCC: item.Payment?.MaskedCC || '',
                      ExpirationMonth: item.Payment?.ExpirationMonth || '',
                      ExpirationYear: item.Payment?.ExpirationYear || '',
                    }}
                  />
                </ResultsListItem>
              )
          )}
        </ResultsList>
      )}
    </div>
  );
};

export default SubscriptionsList;
